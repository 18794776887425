import React, { Component } from "react"
import styled from "styled-components"

import Layout from "../components/Layout"
import Header from "../components/Header/Header"
import Footer from "../components/Footer"

import Container from "react-bootstrap/Container"

import PageTitle from "../components/PageTitle"

const StyledContainer = styled(Container)`
  margin-bottom: 30px;
  padding-left: 0px !important;
  padding-right: 0px !important;
`

const StyledH4 = styled.h4`
    margin-top: 15px;
    margin-bottom: 25px;
`

const StyledP = styled.p`
  font-size: 1.05em;
`
const StyledP2 = styled.p`
  font-size: 1.05em;
  margin-bottom: 1.05em;
`

const StyledLi = styled.li`
    margin-left: 20px;
    font-size: 1.05em;
`

const StyledUl = styled.ul`
    margin-left: 40px;
    font-size: 1.05em;
    margin-bottom: 1.05em;
`

const StyledOl = styled.ol`
    margin-left: 40px;
    font-size: 1.05em;
    margin-bottom: 1.05em;
`

export class Terms extends Component {
  render() {
    return (
      <div>
        <Layout />
        <Header />
          <StyledContainer fluid>
            <PageTitle link="/terms" title="M8 Systems Terms of Service" />
            <div className="container">
                <br></br>
                <StyledP>
                    PLEASE READ THIS LEGAL DOCUMENT CAREFULLY. M8 Systems, Inc. (together with our affiliates, “M8 Systems”, “we”, or “us”) empowers farmers with AIoT irrigation and related products, services, content and features through M8 Systems app and website, such as those for our WaterGuard device, support (the “M8 Systems Site(s)”), 
                    the interfaces on tablets connected to M8 Systems WaterGuard equipment, mobile, desktop, or device applications (including iOS and Android applications (“Apps”)) and M8 Systems- controlled social media pages (including on Facebook, Instagram, Spotify and Twitter). To make these Terms easier to read, the M8 Systems Sites and Apps, 
                    along with the M8 Systems tablet and studio interfaces and M8 Systems-controlled social media pages are collectively called the “M8 Systems Service” or “the Services”. By registering as a member or by visiting, browsing, or using the M8 Systems Service in any way and have your usual residence in the US, you (as a “user”) accept 
                    and agree to be bound by these Terms of Service (“Terms”), which forms a binding agreement between you and M8 Systems.
                </StyledP> 
                <StyledP>
                    PLEASE READ: THESE TERMS CONTAIN A BINDING ARBITRATION PROVISION AND CLASS ACTION WAIVER (SECTION 19). READ CAREFULLY, BY ENTERING INTO THESE TERMS YOU EXPRESSLY AGREE THAT DISPUTES BETWEEN YOU AND M8 SYSTEMS WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION, AND YOU HEREBY WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
                </StyledP>
                <StyledP> 
                    If you do not wish to be bound by these Terms, you may not access or use the M8 Systems Service. Certain elements of the M8 Systems Service may be subject to additional terms and conditions specified from time to time; your use of those elements of the M8 Systems Service is subject to those additional terms and conditions, which are incorporated into these Terms by this reference.
                </StyledP>
                <ol>
                    <li>
                        &nbsp;<u>Who May Use the M8 Systems Service</u>
                        <StyledP2>
                            Age Requirement. You must be at least 18 years old to purchase a M8 Systems membership subscription and become a Membership Holder (as defined in the Membership Terms).
                        </StyledP2>
                        <StyledP2>
                            We may, in our sole discretion, refuse to offer the M8 Systems Service to any person or entity and change the eligibility criteria at any time. You are solely responsible for ensuring that these Terms are in compliance with all laws, rules and regulations applicable to you, and the right to access and use the M8 Systems Service is revoked where these Terms or use of the M8 Systems Service is prohibited or conflicts with any applicable law, rule or regulation. Further, the M8 Systems Service is offered only for your irrigation management solutions, and not for the use or benefit of any third party.        
                        </StyledP2>
                    </li>
                    <li>
                        &nbsp;<u>License to Use the M8 Systems Service License.</u>
                        <StyledP2>
                            Subject to your compliance with these Terms and solely for so long as you are permitted by us to access and use the M8 Systems Service, M8 Systems grants you a limited, non-transferable, non- exclusive, revocable right and license to access and use the M8 Systems Service for your irrigation management solutions, a right which may not be assigned or sublicensed to anyone. This license includes the right to view Content (defined below) available on the M8 Systems Service and the right to download one copy of the application to any single device for your irrigation management purposes, provided you keep intact all copyright and other proprietary notices. This license grant is subject to you agreeing to and abiding by the M8 Systems Intellectual Property and DMCA Policy, which forms a part of these Terms. This license will remain in effect unless and until you violate these Terms or this license is terminated by you or M8 Systems.
                        </StyledP2>
                        <StyledP2>
                            <u>Restrictions.</u>Except as expressly permitted in writing by an authorized representative of M8 Systems, you will not reproduce, redistribute, sell, transfer, create derivative works from, decompile, reverse engineer, or disassemble the M8 Systems Service, nor will you take any measures to interfere with or damage the M8 Systems Service. Unless otherwise specified, copying or modifying any Content or using Content for any purpose other than your irrigation purposes of the M8 Systems Service, including use of any such Content on any other website or networked computer environment, is strictly prohibited. All rights not expressly granted by M8 Systems in these Terms are reserved.
                        </StyledP2>
                    </li>
                    <li>
                        &nbsp;<u>Privacy</u>
                        <StyledP2>
                            Please review the Privacy Policy to learn about:
                        </StyledP2>
                        <StyledUl>
                            <li>What information we may collect about you;</li>
                            <li>What we use that information for; and</li>
                            <li>With whom we share that information.</li>
                        </StyledUl> 
                    </li>
                    <li>
                        &nbsp;<u>Membership Requirements Registration</u>
                        <StyledP2>
                            To enjoy full access to the M8 Systems Service, you must register as a member of the M8 Systems Service and enter into a subscription agreement for access to our AIoT end-to end management solution for irrigation, Content and features (a “Subscription”). Your Subscription is also governed by the Membership Terms. You must provide complete and accurate registration information to M8 Systems, complete the Subscription process, and notify us if any of your information changes. If you fail to keep your account information up to date, we may have to suspend or terminate your Subscription.
                        </StyledP2>
                        <StyledP2>
                            <u>Profile Information.</u>&nbsp;You may not use someone else’s name, or any name, location, other public profile information or image that violates any third party rights, is against the law, or that is offensive, obscene or otherwise objectionable (in M8 System’s sole discretion).
                        </StyledP2>
                        <StyledP2>
                            <u>Account Security.</u>&nbsp;You are responsible for all activity that occurs under your account, including any activity by unauthorized users. You may not allow others to use your account. You must safeguard the confidentiality of your password, and if you are using a device that others have access to, log out of your account after using the M8 Systems Service. If you become aware of an unauthorized access to your account, change your password and notify our Support team immediately.
                        </StyledP2>
                    </li>
                    <li>
                        &nbsp;<u>Membership Structure and Fees</u>
                        <StyledP2>
                            M8 Systems will provide information on its then-current membership and Subscription requirements on the M8 Systems Site and/or by other means through the M8 Systems Service. Features and prices are subject to change.
                        </StyledP2>
                    </li>
                    <li>
                        &nbsp;<u>Sale of Products</u>
                        <StyledP2>
                            M8 Systems accepts orders for its WaterGuard device and any other equipment or accessories that we may offer through the M8 Systems Site. Unfortunately, availability of products cannot be guaranteed. Please note that product, service and other information provided is subject to corrections and changes without notice. Advertising depictions, graphics and diagrams are for illustrative purposes only and may not accurately reflect actual product or component availability. Errors will be corrected where discovered, and we reserve the right to revoke any stated offer and to correct any errors, inaccuracies or omissions.
                        </StyledP2>
                        <StyledP2>
                            Unless otherwise specified at the time of purchase, you must pay for products when you place the order. All products ordered will be delivered to the shipping address you provide. We reserve the right to cancel an order placed by you at any time and for any lawful reason prior to our delivery of the product(s) and receipt of payment in full from you, provided that we will refund any fees that you prepaid for those products if we cancel. We may send an acknowledgment of receipt of your order to the email address you provide and/or proof of purchase information through the M8 Systems Site or to your email address after your payment has been processed. Please see our Warranty and Return Policy for further information about M8 Systems products.
                        </StyledP2>
                    </li>
                    <li>
                        &nbsp;<u>Termination; Account Deletion Term.</u>
                        <StyledP2>
                            These Terms begin on the date you first use the M8 Systems Service and continue as long as you have an account with us and/or continue to use the M8 Systems Service.
                        </StyledP2>
                        <StyledP2>
                            <u>Termination.</u>&nbsp;M8 Systems may, in M8 System’s sole discretion, suspend, disable, or delete your account (or any part thereof) or block or remove any User Content (defined below) that has been compiled or submitted, for any lawful reason, including if M8 Systems determines that you have violated these Terms or that your conduct or User Content would tend to damage M8 System’s reputation or goodwill. If M8 Systems deletes your account, you may not re-register for or use the M8 Systems Service under any other user name or profile. M8 Systems may block your access to the M8 Systems Service to prevent re- registration.
                        </StyledP2>
                        <StyledP2>
                            <u>Effect of Termination / Account Deletion.</u>&nbsp;Upon termination of these Terms all licenses granted by M8 Systems will terminate. The following sections survive termination: Privacy (Section 3), User Content (Section 8), Indemnification (Section 12), No Warranties (Section 15), Limitation of Liability (Section 16), Safety Warnings (Section 17), Intellectual Property (Section 18), Arbitration Requirement & Class Action Waiver (Section 19), Contracting Entities, Governing Law and Jurisdiction (Section 20), and all general provisions. In the event of account deletion for any reason, User Content and data may no longer be available and M8 Systems is not responsible for the deletion or loss of such User Content and data. For clarity, if you cancel your Subscription or it is terminated for any reason, you will lose access to data, dashboards, irrigation management solutions and any other Content or features provided through the M8 Systems Service. M8 Systems, in its sole discretion, may make available a very limited amount of Content or features to non-subscribers from time to time, and any use of those features and Content is governed by these Terms.
                        </StyledP2>
                    </li>
                    <li>
                        &nbsp;<u>User Content</u>
                        <StyledP2>
                            “Content” means text, data, dashboard, graphs, graphics, images, software, audio, video, works of authorship of any kind, and information or other materials that are posted, generated, provided or otherwise made available through the M8 Systems Service; and (ii) ”User Content” means any content that users (including you) provide to be made available through the M8 Systems Service. Content includes, without limitation, data compilation sets and User Content.
                        </StyledP2>
                        <StyledP2>
                            Any User Content, whether publicly posted or privately transmitted, is the sole responsibility of the person who originated such User Content. You represent that all User Content submitted by or on behalf of you is accurate, complete, up-to-date, and in compliance with these Terms and with all applicable laws, rules and regulations. You acknowledge that all Content, including User Content, accessed by you using the M8 Systems Service is at your own risk and you will be solely responsible for any damage or loss to you or any other party resulting therefrom. To the full extent permitted by law, we make no representations, warranties or guarantees with respect to any Content that you access on or through the M8 Systems Service.
                        </StyledP2>
                        <StyledP2>
                            As between you and M8 Systems, you represent that you own (or have all rights necessary to grant M8 Systems the rights below to) all User Content that you submit to the M8 Systems Service, and that M8 Systems will not need to obtain licenses from any third party or pay royalties to any third party in order to use such User Content. You grant M8 Systems a worldwide, perpetual, irrevocable, non-exclusive, sublicensable (through multiple tiers), transferable royalty-free license and right to use, copy, transmit, distribute, publicly perform and display (through all media now known or later created), edit, modify, and make derivative works from your User Content (including, without limitation, translations) for any purpose whatsoever, commercial or otherwise, without compensation to you. In addition, you waive any so-called “moral rights” or rights of privacy or publicity in your User Content. If you make suggestions to M8 Systems or through the M8 Systems Service about improving or adding new features or products to the M8 Systems Service or you otherwise provide feedback, product or service reviews or testimonials, you hereby grant to M8 Systems a worldwide, perpetual, irrevocable, non-exclusive, sublicensable (through multiple tiers), transferable royalty-free license and right to use, copy, modify, create derivative works based upon and otherwise exploit your suggestions, feedback, reviews and testimonials for any purpose (including for marketing), without any notice, compensation or other obligation to you.
                        </StyledP2>
                        <StyledP2>
                            You can remove your User Content by specifically deleting it. However, in certain instances, some of your User Content (such as data compilations or comments you make) may not be completely removed and copies of your User Content may continue to exist on the M8 Systems Service. We are not responsible or liable for the removal or deletion of (or the failure to remove or delete) any of your User Content.
                        </StyledP2>
                    </li>
                    <li>
                        &nbsp;<u>Rights and Terms for Apps, Rights in App Granted</u>
                        <StyledP2>
                            Subject to your compliance with these Terms, M8 Systems grants to you a limited non-exclusive, non-transferable, revocable license, with no right to sublicense, to download and install a copy of the App on a mobile device or computer that you own or control and to run such copy of the App solely for your irrigation management solution purposes. You may not copy the App, except for making a reasonable number of copies for backup or archival purposes. Except as expressly permitted in these Terms, you may not:
                            <StyledOl type="i">
                                <li>copy, modify or create derivative works based on the App;</li>
                                <li>distribute, transfer, sublicense, lease, lend or rent the App to any third party;</li>
                                <li>reverse engineer, decompile or disassemble the App; or</li>
                                <li>make the functionality of the App available to multiple users through any means.</li>
                            </StyledOl>
                        </StyledP2>
                        <StyledP2>
                            M8 Systems reserves all rights in and to the App not expressly granted to you under these Terms.
                        </StyledP2>
                        <StyledP2>
                            <u>Accessing App from an App Store.</u> The following terms apply to any App accessed through or downloaded from any app store or distribution platform (like the Apple App Store or Google Play) where the App may now or in the future be made available (each an “App Provider”). You acknowledge and agree that:
                            <StyledUl>
                                <li>
                                    These Terms are between you and M8 Systems, and not with the App Provider, and M8 Systems (not the App Provider), is solely responsible for the App.
                                </li>
                                <li>
                                    The App Provider has no obligation to furnish any maintenance and support services with respect to the App.
                                </li>
                                <li>
                                    In the event of any failure of the App to conform to any applicable warranty, you may notify the App Provider, and the App Provider will refund the purchase price for the App to you (if applicable) and, to the maximum extent permitted by applicable law, the App Provider will have no other warranty obligation whatsoever with respect to the App. Any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be the sole responsibility of M8 Systems.
                                </li>
                                <li>
                                    The App Provider is not responsible for addressing any claims you have or any claims of any third party relating to the App or your possession and use of the App, including, but not limited to:
                                    <StyledOl type = "i">
                                        <li>
                                            product liability claims;
                                        </li>
                                        <li>
                                            any claim that the App fails to conform to any applicable legal or regulatory requirement; and
                                        </li>
                                        <li>
                                            claims arising under consumer protection or similar legislation.
                                        </li>
                                    </StyledOl>
                                </li>
                                <li>
                                    In the event of any third party claim that the App or your possession and use of that App infringes that third party’s intellectual property rights, M8 Systems will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim to the extent required by these Terms.
                                </li>
                                <li>
                                    The App Provider and its affiliates are third-party beneficiaries of these Terms as related to your license to the App, and that, upon your acceptance of the Terms, the App Provider will have the right (and will be deemed to have accepted the right) to enforce these Terms as related to your license of the App against you as a third-party beneficiary thereof.
                                </li>
                                <li>
                                    You represent and warrant that
                                    <StyledOl type="i">
                                        <li>
                                            you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a terrorist-supporting country;
                                        </li>
                                        <li>
                                            you are not listed on any U.S. Government list of prohibited or restricted parties; and
                                        </li>
                                        <li>
                                            you are not otherwise subject to or affected in any way by any national security or terrorism related rules whether applicable to you personally or to your location or other circumstances.
                                        </li>
                                    </StyledOl>
                                </li>
                                <li>
                                    You must also comply with all applicable third party terms of service when using the App.
                                </li>
                            </StyledUl>
                        </StyledP2>
                    </li>
                    <li>
                        &nbsp;<u>General Prohibitions and M8 System's Enforcement Rights</u>
                        <StyledP2>
                            You agree not to do any of the following:
                            <StyledOl>
                                <li>
                                    Post, upload, publish, submit or transmit any User Content or engage in any activity that:
                                    <StyledUl>
                                        <li>
                                            infringes, misappropriates or violates a third party’s patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy;
                                        </li>
                                        <li>
                                            violates, or encourages any conduct that would violate, any applicable law or regulation or would give rise to civil liability;
                                        </li>
                                        <li>
                                            is fraudulent, false, misleading or deceptive;
                                        </li>
                                        <li>
                                            is defamatory, obscene, pornographic, vulgar or offensive;
                                        </li>
                                        <li>
                                            promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group;
                                        </li>
                                        <li>
                                            is violent or threatening or promotes violence or actions that are threatening to any person, animal, or entity;
                                        </li>
                                        <li>
                                            exploits minors or
                                        </li>
                                        <li>
                                            promotes illegal or harmful activities or substances;
                                        </li>
                                    </StyledUl>
                                </li>
                                <li>
                                    Download and/or install any third party software and/or application on any M8 Systems hardware (excluding assistive technologies that are necessary for your own use of the M8 Systems Service, such as screen-readers) that is not expressly permitted by M8 Systems in writing;
                                </li>
                                <li>
                                    Use, display, mirror or frame the M8 Systems Service or any individual element within the M8 Systems Service, M8 System’s name, any M8 Systems trademark, logo or other proprietary information, or the layout and design of any page or form contained on a page, without M8 System’s express written consent;
                                </li>
                                <li>
                                    Access, tamper with, or use non-public areas of the M8 Systems Service, M8 System’s computer systems, or the technical delivery systems of M8 System’s providers;
                                </li>
                                <li>
                                    Attempt to probe, scan or test the vulnerability of any M8 Systems system or network or breach any security or authentication measures;
                                </li>
                                <li>
                                    Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure implemented by M8 Systems or any of M8 System’s providers or any other third party (including another user) to protect the M8 Systems Service or Content;
                                </li>
                                <li>
                                    Bypass any territorial restrictions, including IP address-based restrictions, that may be applied to the M8 Systems Service;
                                </li>
                                <li>
                                    Attempt to access, scrape or search the M8 Systems Service or Content or download Content from the M8 Systems Service, including through the use of any engine, software, tool, agent, device or mechanism (including spiders, robots, crawlers, data mining tools, plugins, add-ons or the like), other than the software and/or search agents provided by M8 Systems or other generally available third-party web browsers; 
                                </li>
                                <li>
                                    Send any unsolicited or unauthorized advertising, promotional materials, email, junk mail, spam, chain letters or other form of solicitation;
                                </li>
                                <li>
                                    Use any meta tags or other hidden text or metadata utilizing a M8 Systems trademark, logo URL or product name without M8 System’s express written consent;
                                </li>
                                <li>
                                    Use the M8 Systems Service or Content, or any portion thereof, for any commercial purpose or for the benefit of any third party or in any manner not permitted by these Terms or permitted expressly in writing by M8 Systems;
                                </li>
                                <li>
                                    Forge any TCP/IP packet header or any part of the header information in any email or newsgroup posting, or in any way use the M8 Systems Service or Content to send altered, deceptive or false source-identifying information;
                                </li>
                                <li>
                                    Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the M8 Systems Service or Content;
                                </li>
                                <li>
                                    Interfere with, or attempt to interfere with, the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the M8 Systems Service;
                                </li>
                                <li>
                                    Collect or store any personally identifiable information from the M8 Systems Service from other users of the M8 Systems Service without their express permission;
                                </li>
                                <li>
                                    Copy, use, index, disclose or distribute any information or data obtained from the M8 Systems Service, whether directly or through third parties (such as search engines), without M8 System’s express written consent;
                                </li>
                                <li>
                                    Alter, replicate, store, distribute or create derivatives from the Content available via the M8 Systems Service except as expressly permitted in writing by M8 Systems;
                                </li>
                                <li>
                                    Impersonate or misrepresent your affiliation with any person or entity;
                                </li>
                                <li>
                                    Access, use or exploit the M8 Systems Service in any manner (other than as expressly permitted by these Terms), including to build, develop (or commission the development of), replicate, or consult upon any product or service that may compete (directly or indirectly) with M8 Systems or the M8 Systems Service;
                                </li>
                                <li>
                                    Violate any applicable law or regulation; or
                                </li>
                                <li>
                                    Encourage or enable any other individual to do any of the foregoing.
                                </li>
                            </StyledOl>
                        </StyledP2>
                        <StyledP2>
                            Although we’re not obligated to monitor access to or use of the M8 Systems Service or Content or to review or edit any User Content, we have the right to do so for the purpose of operating the M8 Systems Service, to ensure compliance with these Terms, to comply with applicable law or other legal requirements and to maintain the integrity and reputation of the M8 Systems Service and M8 System’s systems. We reserve the right, but are not obligated, to remove or disable access to any User Content, at any time and without notice, including, but not limited to, if we, at our sole discretion, consider any User Content to be objectionable or in violation of these Terms. We have the right to investigate violations of these Terms or conduct that affects the M8 Systems Service. We may also consult and cooperate with law enforcement authorities to prosecute users who violate the law.
                        </StyledP2>
                    </li>
                    <li>
                        &nbsp;<u>Member Interactions, Dealings with Third Parties</u>
                        <StyledP2>
                            When interacting with other M8 Systems members, you should exercise caution and common sense to protect your personal safety and property, just as you would when interacting with other people you don’t know. Your participation, correspondence or personal or business dealings with any third party found on or through the M8 Systems Service, whether regarding payment or delivery of specific goods and services, and any other terms, conditions, representations or warranties associated with such dealings, are solely between you and such third party. You agree that M8 Systems is not responsible or liable for any loss, damage, injury, or other matters of any sort incurred as the result of such dealings.
                        </StyledP2>
                    </li>
                    <li>
                        &nbsp;<u>Indemnification</u>
                        <StyledP2>
                            You agree to indemnify, defend, and hold harmless M8 Systems and its directors, officers, employees, and agents, from and against all claims, damages, losses and costs that arise from or relate to
                            <StyledOl type="i">
                                <li>your activities on the M8 Systems Service,</li>
                                <li>any User Content submitted by or on behalf of you or</li>
                                <li>your violation of these Terms.</li>
                            </StyledOl>
                        </StyledP2>
                    </li>
                    <li>
                        &nbsp;<u>Third Party Software and Applications</u>
                        <StyledP2>
                            Downloading and/or installing any third party software and/or applications that are not expressly authorized by M8 Systems on any M8 Systems hardware constitutes a breach of these Terms; any such downloading or installation is done at your own risk and may void any applicable warranty or support commitments by M8 Systems.
                        </StyledP2>
                    </li>
                    <li>
                        &nbsp;<u>Third Party Links and Content</u>
                        <StyledP2>
                            There may be links on the M8 Systems Service that let you leave the particular M8 Systems Service you are accessing in order to access a linked site that is operated by a third party. M8 Systems neither controls nor endorses these sites, nor has M8 Systems reviewed or approved the content that appears on them. M8 Systems is not responsible for the legality, accuracy or inappropriate nature of any content, advertising, products or other materials on or available from any such third party sites. You acknowledge and agree that M8 Systems is not responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the access or use of any of the links, content, goods or services available on or through these third party sites.
                        </StyledP2>
                    </li>
                    <li>
                        &nbsp;<u>No Warranties</u>
                        <StyledP2>
                            M8 Systems reserves the right to modify the M8 Systems Service, including, but not limited to updating, adding to, enhancing, modifying, removing or altering any Content or features of the M8 Systems Service, at any time, in its sole discretion. You are responsible for providing your own access (e.g., computer, mobile device, cellular connection or internet connection, etc.) to the M8 Systems Service. M8 Systems has no
                            obligation to screen or monitor any Content and does not guarantee that any Content available on the M8 Systems Service is suitable for all users or that it will continue to be available for any length of time.
                        </StyledP2>
                        <StyledP2>
                            M8 Systems provides the M8 Systems Service on an “AS IS” and “AS AVAILABLE” basis. You therefore use the M8 Systems Service at your own risk. Other than as expressly provided in writing by M8 Systems in connection with your purchase of a M8 Systems product, to the extent permitted by law, M8 Systems expressly disclaims any and all warranties of any kind, whether express or implied, including, but not limited to implied warranties of merchantability, fitness for a particular purpose, non-infringement, quiet enjoyment and any other warranty that might arise under any law. Without limiting the foregoing, M8 Systems makes no representations or warranties:
                            <StyledUl>
                                <li>
                                    That the M8 Systems Service is or will be permitted in your jurisdiction;
                                </li>
                                <li>
                                    That the M8 Systems Service will be uninterrupted or error-free;
                                </li>
                                <li>
                                    Concerning any Content, including User Content;
                                </li>
                                <li>
                                    Concerning any third party’s use of User Content that you submit;
                                </li>
                                <li>
                                    That the M8 Systems Service will meet your personal or professional needs;
                                </li>
                                <li>
                                    That M8 Systems will continue to support any particular feature of the M8 Systems Service; or
                                </li>
                                <li>
                                    Concerning sites and resources outside of the M8 Systems Service, even if linked to from the M8 Systems Service.
                                </li>
                            </StyledUl>
                        </StyledP2>
                        <StyledP2>
                            To the extent that another party may have access to or view Content on your device, you are solely responsible for informing such party of all disclaimers and warnings in these Terms. TO THE EXTENT ANY DISCLAIMER OR LIMITATION OF LIABILITY DOES NOT APPLY, TO THE FULLEST EXTENT PERMITTED BY LAW, ALL APPLICABLE EXPRESS, IMPLIED, AND STATUTORY WARRANTIES WILL BE LIMITED IN DURATION TO A PERIOD OF 30 DAYS AFTER THE DATE ON WHICH YOU FIRST USED THE M8 SYSTEMS SERVICE, AND NO WARRANTIES SHALL APPLY AFTER SUCH PERIOD.
                        </StyledP2>
                    </li>
                    <li>
                        &nbsp;<u>Limitation of Liability</u>
                        <StyledP2>
                            To the fullest extent permitted by law:
                            <StyledOl type="i">
                                <li>
                                    M8 Systems shall not be liable for any indirect, incidental, special, consequential, punitive, or exemplary damages, including but not limited to damages for loss of profits, economic or pure economic losses, goodwill, use, data, service interruption, computer damage, system failure, inability to use the M8 Systems Service or Content or other intangible losses, even if a limited remedy set forth herein is found to have failed its essential purpose; and
                                </li>
                                <li>
                                    M8 System’s total liability to you for all claims, in the aggregate, will not exceed the amount actually paid by you to M8 Systems over the 12 months preceding the date your first claim(s) arose.
                                </li>
                            </StyledOl>
                        </StyledP2>
                        <StyledP2>
                            The exclusions and limitations of damages set forth above are fundamental elements of the basis of the bargain between M8 Systems and you.
                        </StyledP2>
                    </li>
                    <li>
                        &nbsp;<u>Intellectual Property Acknowledgment</u>
                        <StyledP2>
                            You acknowledge and agree that your use of the M8 Systems Services and any Content contained therein is dependent upon you agreeing to and abiding by the M8 Systems Intellectual Property Policy at all times. You further acknowledge that the M8 Systems Service contains software and other content that is protected by copyrights, patents, trademarks, trade secrets or other proprietary rights, and that these rights are valid and protected in all forms, media and technologies existing now or hereafter developed. All M8 Systems- generated content, and content provided to M8 Systems by its partners and licensors, is copyrighted individually and/or as a collective work under the U.S. copyright laws and all applicable international copyright (or equivalent) laws in all jurisdictions and protected under other intellectual property laws worldwide; further, as between you and M8 Systems, M8 Systems own intellectual property rights in the selection, coordination, arrangement and enhancement of all content in the M8 Systems Service.
                        </StyledP2>
                    </li>
                    <li>
                        &nbsp;<u>Intellectual Property Usage and Reporting Infringement</u>
                        <StyledP2>
                            M8 Systems respects the intellectual property of others, and we ask our users to do the same. Each user is responsible for ensuring that their User Content does not infringe any third party’s right or other intellectual property rights. If you believe that the M8 Systems Service or any Content contains elements that infringe or misappropriate your copyrights or other intellectual property rights (or the intellectual property rights of others), please go to the M8 Systems Intellectual Property Policy for directions on how to report it to us.
                        </StyledP2>
                    </li>
                    <li>
                        &nbsp;<u>ARBITRATION REQUIREMENT & CLASS ACTION WAIVER – IMPORTANT – PLEASE REVIEW AS THIS MAY AFFECT YOUR LEGAL RIGHTS. APPLICABLE TO THE FULL EXTENT PERMITTED BY LAW.</u>
                        <StyledOl>
                            <li>
                                &nbsp;<u>Mandatory Arbitration of Disputes.</u> We each agree that any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation or validity thereof or the use of the Services or Content (collectively, “Disputes”) will be resolved solely by binding, individual arbitration and not in a class, representative or consolidated action or proceeding. You and M8 Systems agree that the U.S. Federal Arbitration Act (or equivalent laws in the jurisdiction in which the M8 Systems entity that you have contracted with is incorporated) governs the interpretation and enforcement of these Terms and that you and M8 Systems are each waiving the right to a trial by jury or to participate in a class action. This arbitration provision shall survive termination of these Terms.
                            </li>
                            <li>
                                &nbsp;<u>Exceptions and Opt-out.</u> As limited exceptions to Section 19(1) above:
                                <StyledOl type="i">
                                    <li>
                                        you may seek to resolve a Dispute in small claims court if it qualifies; and
                                    </li>
                                    <li>
                                        we each retain the right to seek injunctive or other equitable relief from a court to prevent (or enjoin) the infringement or misappropriation of our respective intellectual property rights.
                                    </li>
                                </StyledOl>
                            </li>
                            <li>
                                &nbsp;<u>Initial Dispute Resolution and Notification.</u> You agree that before initiating any Dispute or arbitration proceeding, we will attempt to negotiate an informal resolution of any dispute. To begin this process, before initiating any arbitration proceeding, you must send a Notice of Dispute (“Notice”) by certified mail to the attention of M8 System’s Legal Department at the M8 Systems address set out in Section 23 below. In the Notice, you must describe the nature and basis of the Dispute and the relief you are seeking. If we are unable to resolve the Dispute within 45 days after M8 System’s receipt of the Notice, then you or M8 Systems may initiate arbitration proceedings as set out below.
                            </li>
                            <li>
                                &nbsp;<u>Conducting Arbitration and Arbitration Rules.</u> Any arbitration will be conducted by JAMS pursuant to its Streamlined Arbitration Rules and Procedures (the “JAMS Rules”) then in effect, except as modified by these Terms. The JAMS Rules are available at www.jamsadr.com or by calling 1-800-352-5267. A party who wishes to start arbitration must submit a written Demand for Arbitration to JAMS and give notice to the other party as specified in the JAMS Rules. JAMS provides a form Demand for Arbitration at www.jamsadr.com. JAMS will appoint an arbitrator that is either
                                <StyledOl type="i">
                                    <li>
                                        a retired federal or state court judge, or
                                    </li>
                                    <li>
                                        an attorney who has been licensed to practice law in the state of California for at least 10 years. The arbitration will be conducted by an in-person hearing, unless we both agree otherwise.
                                    </li>
                                </StyledOl>
                                <StyledP2>
                                    If JAMS fails or declines to conduct the arbitration for any reason, we will mutually select a different arbitration administrator. If we cannot agree, a court will appoint a different arbitration administrator. Any arbitration hearings will take place in the county (or other municipality) where you live, unless we both agree to a different location. The parties agree that the arbitrator shall have exclusive authority to decide all issues relating to the interpretation, applicability, enforceability and scope of this arbitration agreement. During the arbitration, both you and M8 Systems may take one deposition of the opposing party, limited to 4 hours. If we cannot agree on a time and location for a deposition, the arbitrator will resolve any scheduling disputes.
                                </StyledP2>
                            </li>
                            <li>
                                &nbsp;<u>Arbitration Costs.</u> Payment of all filing, administration and arbitrator fees will be governed by the JAMS Rules. Each party will bear its own costs of filing and attorney fees, unless the arbitrator, upon final disposition of the case, allocates such fees to the extent provided under applicable law.
                            </li>
                            <li>
                                &nbsp;<u>Class Action Waiver.</u> YOU AND M8 SYSTEMS AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY IN AN INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, if the parties’ dispute is resolved through arbitration, neither JAMS nor the arbitrator may consolidate another person’s claims with your claims or otherwise preside over any form of a representative or class proceeding for any purpose. If this specific provision is found to be unenforceable, then the entirety of this Section 19 shall be null and void.
                            </li>
                            <li>
                                &nbsp;<u>Severability.</u> With the exception of any of the provisions in Section 19(6) above, if an arbitrator or court of competent jurisdiction decides that any part of these Terms is invalid or unenforceable, the other parts of these Terms will still apply.
                            </li>
                        </StyledOl>
                    </li>
                    <li>
                        &nbsp;<u>Contracting Entities, Governing Law and Jurisdiction</u>
                        <StyledP2>
                            All users are contracting with M8 Systems, Inc. unless otherwise specified in these Terms. These Terms shall be governed by the laws of the State of California, United States of America, without regard to principles of conflicts of law. The Uniform Commercial Code, the Uniform Computer Information Transaction Act, and the United Nations Convention of Controls for International Sale of Goods shall not apply.
                        </StyledP2>
                    </li>
                    <li>
                        &nbsp;<u>Interpretation; Severability; Waiver; Remedies</u>
                        <StyledP2>
                            Headings are for convenience only and shall not be used to construe these Terms. If any term of these Terms is found invalid or unenforceable by any court of competent jurisdiction, that term will be severed from these Terms. No failure or delay by M8 Systems in exercising any right hereunder will waive any further exercise of that right. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of M8 Systems. M8 Systems rights and remedies hereunder are cumulative and not exclusive.
                        </StyledP2>
                    </li>
                    <li>
                        &nbsp;<u>Successors; Assignment; No Third Party Beneficiaries</u>
                        <StyledP2>
                            These Terms are binding upon and will inure to the benefit of both parties and their respective successors, heirs, executors, administrators, personal representatives, and permitted assigns. You may not assign or transfer these Terms without M8 System’s prior written consent. M8 Systems may assign its rights, obligations and/or these Terms at any time in its sole discretion without notice to you.
                        </StyledP2>
                    </li>
                    <li>
                        &nbsp;<u>Notices</u>
                        <StyledP2>
                            You consent to receive all communications including notices, agreements, disclosures, or other information from M8 Systems electronically. M8 Systems may communicate by email or by posting to the M8 Systems Service. For support-related inquiries, you may email Support. For all other notices to M8 Systems, write to the following address:
                        </StyledP2>
                        <StyledP>
                            <i>M8 Systems, Inc.</i><br></br>
                            <i>30962 Via Mirador</i><br></br>
                            <i>San Juan Capistrano, CA 92675</i><br></br>
                            <i>Attn. Legal Department</i><br></br>
                        </StyledP>
                        <StyledP>
                            Nothing in these Terms or otherwise limits M8 System’s right to object to subpoenas, claims, or other demands.
                        </StyledP>
                    </li>
                    <li>
                        &nbsp;<u>Modification</u>
                        <StyledP2>
                            We may update these Terms at any time, in our sole discretion. If we do so, we’ll let you know by, at a minimum, posting the updated Terms (as indicated by a revised “Last Updated” date at the top of this page) on the M8 Systems Site and/or through the M8 Systems Service. Modifications will be effective on the date that they are posted to the M8 Systems Site. It’s important that you review the Terms whenever we update them before you use the M8 Systems Service. If you continue to use the M8 Systems Service after we have posted updated Terms, you are agreeing to be bound by the updated Terms. If you don’t agree to be bound by the updated Terms, then you may not use the M8 Systems Service anymore. Because the M8 Systems Service is evolving over time we may change or discontinue all or any part of the M8 Systems Service, at any time and without notice, at our sole discretion.
                        </StyledP2>
                    </li>
                    <li>
                        &nbsp;<u>Entire Agreement</u>
                        <StyledP2>
                            These Terms incorporate the following legal documents by reference, as well as any other policies or procedures referenced herein that are posted to the M8 Systems Site from time to time:
                            <StyledUl>
                                <li>Membership Terms</li>
                                <li>EULA</li>
                                <li>Warranty</li>
                                <li>Return Policy</li>
                                <li>Intellectual Property Policy</li>
                                <li>Privacy Policy</li>
                            </StyledUl>
                        </StyledP2>
                        <StyledP2>
                            In the event of a conflict between any policies posted on the M8 Systems Service and these Terms, these Terms will control. These Terms represent the entire understanding between M8 Systems and you regarding the M8 Systems Service or Content and supersede all prior agreements and understandings regarding the same. If any provision of these Terms is held invalid or unenforceable by an arbitrator or a court of competent jurisdiction, that provision will be enforced to the maximum extent permissible and the other provisions of these Terms will remain in full force and effect.
                        </StyledP2>
                    </li>
                    <li>
                        &nbsp;<u>Force Majeure</u>
                        <StyledP2>
                            Neither Party shall be liable for any failure or delay in performance under these terms (other than for delay in the payment of money due and payable in accordance with these terms) for causes beyond the party’s reasonable control and not caused by that party’s fault, or negligence, including, but not limited to, “acts of God”, acts of government, flood, fire, pandemic, civil unrest, acts of terror, strikes or other labor problems, computer attacks or malicious acts, such as attacks on or through the internet, any internet service provider, telecommunications or hosting facility, but in each case, only if and to the extent that the non-performing Party is without fault in causing such failure or delay, and the failure or delay could not have been prevented by reasonable precautions and measures and cannot reasonably be circumvented by the non-conforming Party through the use of alternate sources, workaround plans, disaster recovery, business continuity measures or other means. Dates by which performance obligations are scheduled to be met will be extended for a period of time equal to the time lost due to any delay so caused.
                        </StyledP2>
                    </li>
                </ol>
           </div>
          </StyledContainer>
        <Footer />
      </div>
    )
  }
}
export default Terms 